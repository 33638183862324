<template>
  <div class="page">
    <h1
      style="
        text-align: center;
        line-height: 240%;
        widows: 2;
        orphans: 2;
        font-size: 16pt;
      "
    >
      <span style="font-family: 黑体">供应商入驻合作协议</span>
    </h1>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >本协议是就供应商（以下简称“乙方”）与中山苗仓供应链管理有限公司（以下简称“甲方”）开发的苗仓“地被直采中心”平台（以下简称“平台”）产品交易事宜所订立的协议。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >为了维护甲方平台正常的管理秩序，保障乙方在平台的良好体验和合法权益，乙方开始注册并使用平台的乙方服务，即表示乙方已充分阅读、理解并自愿接受本协议，同意受本协议各项条款的约束，用于更好的约束双方，有序开展交易。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第一条 协议成立及生效</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体"
        >1.1乙方签订本协议前已充分阅读本协议条款、苗仓《供应商服务标准》等相关文件。以上文件为本协议不可分割的一部分，与本协议具有同等法律效力。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体"
        >1.2乙方签订本协议、缴纳诚信保证金并经平台审核相关乙方入驻资质，审核通过后平台五个工作日内以短信的形式通知乙方已开通帐号功能，同时本协议成立及生效。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第二条 期限和终止</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">2.1期限</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">自签署之日起至协议解除之日止。</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-weight: bold">2.2 终止</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方有权申请解除本协议、终止合作，甲方审核通过后本协议终止</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方违反平台规则达到清退处罚的，则本协议终止。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >2.3本协议终止前的事项：</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >正在履行或已履行的订单已完成所有的业务服务</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >本协议终止乙方按本协议第五条中的相关细则条款办理退还保证金。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >2.4协议终止后事项的处理
      </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >自本协议终止之日起，平台将关闭乙方在平台的账户权限，乙方将无法再通过该账户进行任何形式的操作及交易。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >本协议终止后，甲方平台有权保留商家的注册信息及交易行为记录等数据，但甲方没有为乙方保留这些数据的义务，亦不承担在协议终止后向乙方提供任何数据信息的义务，也不就协议终止向乙方或任何第三方承担责任，但法律另有规定的除外。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第三条 合作内容</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">3.1合作内容</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >甲方利用平台网络技术和营销等优势，提供苗仓“地被直采中心”平台的日常维护、技术支持，保证平台的正常运作。作为互联网经营者，甲方制定各项平台规则、为乙方开放苗圃端交易端口服务，派发订单给乙方在甲方的平台实行交易。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方按平台的各项规则要求，为平台提供从产品上架（价格与库存）、接单、质检、起苗、打包、装车、配合物流、发票、售后等一切订单交易闭环的服务</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第四条乙方入驻</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">4.1入驻要求</span>
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >4.1有营业执照的供应商</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">
        营业执照(三证合一）复印件加盖鲜章、场地合规证明文件{多个苗圃的提供相对应的多个（土地承包合同或《土地使用权证明》），属一手租赁的场地提供《土地租赁合同》，二手转租的场地提供与一手场主签订的《土地权使用证明》}；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">供应商法人身份证正反面复印件；</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">
        供应商营业执照对应公司的银行开户信息，优先中国工商银行，其次中国农业银行、中国银行、中国建设银行；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >供应商场主常驻于中山横栏，有产权属自营的苗圃，
        具有一定生产规模的苗圃和苗木品类；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; font-size: 5.5pt"> </span
      ><span style="font-family: 宋体"
        >能够将自营苗圃苗木资源信息上架到平台；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">
        供应商供应的产品品类应符合苗仓“地被直采中心”《平台经营产品类目表》，供应产品质量必须满足苗仓《供应商服务标准》中的出圃标准；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >供应商法人具有良好的商业信誉，诚实守信，近三年没有重大法律纠纷和违反安全生产的不良记录；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">
        供应商场主有3年以上地被苗木种植供应经验；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; font-size: 5.5pt"> </span
      ><span style="font-family: 宋体"
        >需缴纳人民币3000元作为乙方在平台交易的诚信保证金。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >4.2没有营业执照的供应商</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; font-size: 5.5pt"> </span
      ><span style="font-family: 宋体"
        >场地合规证明文件{多个苗圃的提供相对应的多个（土地承包合同或土地使用权证明），属一手租赁的场地提供《土地租赁合同》，二手转租的场地提供与一手场主签订的《土地使用权证明》}；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"> 供应商法人身份证正反面复印件；</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">与合作社签订的《合作协议》；</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >合作社的营业执照(三证合一）复印件加盖鲜章；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; font-size: 5.5pt"> </span
      ><span style="font-family: 宋体">合作社的法人身份证正反面复印件；</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >合作社的银行开户信息，优先中国工商银行，其次中国农业银行、中国银行、中国建设银行；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">
        供应商场主常驻于中山横栏，有产权属自营的苗圃，
        具有一定生产规模的苗圃和苗木品类；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; font-size: 5.5pt"> </span
      ><span style="font-family: 宋体"
        >能够将自营苗圃苗木资源信息上架到平台；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">
        供应商供应的产品品类应符合苗仓“地被直采中心”《平台经营产品类目表》，供应产品质量必须满足苗仓《供应商服务标准》中的出圃标准；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >供应商场主本人具有良好的商业信誉，诚实守信，近三年没有重大法律纠纷和违反安全生产的不良记录；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">
        供应商场主本人有3年以上地被苗木种植供应经验；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; font-size: 5.5pt"> </span
      ><span style="font-family: 宋体"
        >需缴纳人民币3000元作为乙方在平台交易的诚信保证金。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">4.2入驻流程</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">填写苗仓《供应商调查申请表》 </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >提交相关资质文件及发布商品的实时照片</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">签订本协议</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">缴纳诚信保证金</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">交网格招商专员录入系统</span>
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >4.3入驻审核
      </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >苗仓“地被直采中心”平台审核，审核通过后5个工作日内平台以短信的形式通知乙方</span
      ><span style="font-family: 宋体; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >在协议履行过程中，如相关信息（如营业执照、合作社信息、场地证明文件、发人身份证）发生任何变更的乙方必须第一时间报平台招商专员重新审核。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">4.4入驻登录</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方收到审核通过短信后，进入小程序端，凭正确的手机号码及平台操作流程登录平台。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第五条 诚信保证金</span
      ><span
        style="
          font-family: 黑体;
          font-size: 13pt;
          font-weight: bold;
          color: #ff0000;
        "
        >（待确定保证金的支付节点①在入驻时缴纳②在平台派第一笔订单前缴纳）</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >5、保证金的缴纳、扣罚、退还</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">5.1缴纳</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方与平台签订本协议的同时，同步向平台缴纳人民币叁仟圆（￥3000.00),由平台方开具盖有中山苗仓供应链管理有限公司财务章的《收据》做为凭证</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">5.2退还</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >若乙方签约合作协议之日起达到一年以上且乙方行为不存在任何甲方平台的违规行为，甲方在指定时间内（30个工作日，节假日顺延）全额无息退还乙方诚信保证金</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >若乙方签约合作协议之日起生效未达到一年申请退还保证金（乙方主动申请），则甲方无需退还乙方诚信保证金人民币叁仟圆整（￥3000.00）</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >诚信保证金的方式：由乙方提供收据原件，甲方在指定时间内（30个工作日，节假日顺延）无息全额退还乙方诚信保证金。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第六条 供应信息发布</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方通过甲方发送的短信验证码在小程序端登录商城</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方结合《平台经营产品类目表》在平台商城端发布供应信息，甲方将根据平台的管理政策核验后生效，信息的真实性或合法性由乙方承担全部责任。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第七条 上架、订单管理办法</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">7.1上架</span>
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >7.1.1上架流程</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; color: #ff0000">苗圃主管账号登录前</span
      ><span style="font-family: 宋体"
        >确定后台成功创建企业并关联至少一个苗圃或多个苗圃</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >按后台创建苗圃的时间顺序由近及远排序</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方按照平台的操作指引，进行自有苗圃的产品上架。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >7.1.2产品的价格管理办法</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方定价的基本依据：生产经营成本和市场供求状况，应当遵循公平、合法和诚实信用的原则。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方不得虚假报价、哄抬价格、恶意降价、采取非正常竞争手段扰乱平台价格秩序等。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方向平台展示的商品价格应准确、有效，除供采双方协商一致取消订单的情况外，不得以价格公布失误为理由，拒绝对已生成的订单履行合同项下约定的义务。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方对其在平台上架产品价格范围内所供应的产品质量必须满足苗仓《供应商服务标准》中的验收标准。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >7.1.3上架产品的库存管理办法</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方在自有苗圃里上架的地被品类确保属自有场地里实有品类，不得无空挂货。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >上架品类的库存数据，乙方保证必须是有效库存，如苗圃有发生售卖行为，当及时做相应实时更新库存数据，不得出现平台派订单后，出现缺货、无货等行为。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台派发订单后，系统自动占用的库存，乙方必须优先满足平台的需求供应，不得将平台占用的库存售卖给第三方</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >7.2订单管理办法</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">7.2.1接单</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台在派发订单，以消息提醒的方式通知乙方，订单内容包含（单号、平台跟单员相关信息、取苗时间、总订苗量、苗木明细、订单金额），乙方根据自身具体情况，“确认接单”或“拒绝接单”，</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方在平台派发的订单30分钟内，未及时响应“确认接单”或“拒绝接单”时，平台自动取消订单</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方“确认接单”后根据苗仓《供应商服务标准》中的要求进行订单履约。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">7.2.2起苗</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方按照待备苗的订单明细，结合苗仓《供应商服务标准》中的要求进行起苗、打包、码放，装车。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt">7.2.3装车</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >车辆到达场地后，乙方配合司机，完成每个单品的拍照、装车后的拍照，《送货单》二维码交司机签收。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >7.2.4发货、收款、发票</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方装车完成，在系统确认操作装车完成后，司机离场，平台根据发货总额支付该批次苗木100%款项到乙方指定的帐号上。乙方收到货款后72小时内开具该批次货款相应金额的增值税免税《发票》并拍照上传到平台。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >7.2.5订单注意事项</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >除不可抗力外乙方不得拒绝已确认的采购订单</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >传达精准的装货点定位、联系方式给平台；装货司机到达前，保证装货点周边路况通畅，货车能顺利到达；除不可抗力外，乙方不得在承诺装车时间内无故拖延装车；因天气、不可抗力无法按时装货，应提前通知司机和平台跟单员。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第八条 乙方的权利和义务</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >8.1
        乙方有权使用本协议下的服务所包含的各项内容，并有权按本协议约定使用平台。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >8.2
        乙方遵守平台各项规则的前提下，有权自行修改上架产品的信息资料。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体">8.3 乙方应保证：</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方在甲方的平台上架的或向甲方平台的工作人员提供的全部信息资料是真实、合法和准确的，未违反相关法律法规、政策文件，不侵犯他人合法权益。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方应具有履行本协议所需的行为能力及所有必要的资质和授权。乙方应当按平台规则及本协议约定提交真实的资质等证明文件及联系人、联系地址、联系电话、联系邮箱等信息。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方不以任何手段获取他人的保密信息；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >严格保密并妥善保管自有的账号和登录信息，并明确同意登录后进行的任何操作（包括但不限于发布信息资料等）均应被视为乙方自己的行为，法律后果由乙方承担。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方拥有合法的权利上架发布的产品信息，产品符合需要的国家各种关于质量、检验检疫、卫生等方面的标准和要求，且保证所上架及交付产品必须符合甲方的《供应商服务标准》中的要求。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >8.4乙方应遵守甲方平台现有的所有平台规则及后续更新的使用条款及规则。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >8.5乙方的工商信息发生变更的，应当及时向甲方提交变更后的材料进行审核
        ，否则引发的任何后果，由乙方自行承担。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第九条 甲方的权利和义务</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >9.1
        甲方平台有权采用系统对乙方进入资质及上架的产品信息资料进行审核，如发现违反相关法律法规、政策文件，违反本协议及其它平台规则的，有权要求乙方修改或删除相关信息资料，直到审核通过为止。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >9.2
        甲方平台有权受理任何第三方提起和乙方相关的任何性质的投诉，并根据性质、数量等相关信息及其合理判断，对投诉做出适当的处理，包括但不限于直接封禁乙方的帐号、清退等。乙方有义务配合甲方处理，如乙方不履行配合处理义务，甲方有权中止或终止本协议下的服务。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >9.3甲方平台有权根据服务发展需要，在不减少乙方的权利或增加乙方的义务的前提下，将本协议项下的全部或部分权利义务转让给其关联公司，转让本协议应以苗仓“地被直采中心”平台公告的形式提前通知乙方，转让应于公告通知确定的日期生效。乙方承诺对此不持有异议。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体">9.4甲方有权不断改进本协议下的服务。</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十条 违规及处罚措施</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1违规行为内容</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.1上架产品的价格违规</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台运用大数据、历史成交单价、实地抽查等方式核实上架价格的有效性、真实性</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >虚假报价、哄抬价格、恶意降价、采取非正常竞争手段扰乱平台价格秩序。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.2上架产品库存违规</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台库存抽查，平台每周不定期有专职人员对乙方的库存品种及数据进行抽查，监督乙方在平台上的数据真实性</span
      ><span style="font-family: 宋体; -aw-import: spaces">&nbsp; </span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >上架品类的库存数据与现场场地真实库存不相符；有发生售卖行为后，必须每天及时更新</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台派发订单后，出现缺货、无货等行为。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.3订单管理违规</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >拒绝已确认的采购订单(不可抗力除外）</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体; text-decoration: line-through"
        >装货点定位信息、联系方式给错平台，导致装货延误</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >未按苗仓《供应商服务标准》中的要求交货，导致货物到场后出现的售后问题</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >在承诺的时间内拖延装车（不可抗力除外）</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >场地进场道路路况未及时与装货司机沟通，导致司机绕路延迟装货</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.4骗取他人财物</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体"
        >用户以非法获利为目的，用非法手段或违反平台规则、协议约定的形式，获取他人财物，涉嫌侵犯他人财产权的行为。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.5操纵交易或干扰交易系统</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体"
        >用户操纵交易或使用任何装置、软件或例行程序干扰交易系统。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.6临时增加费用</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体"
        >乙方临时要求增加费用（包括但不限于确认订单后抬高订单单价）。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.7实地看货违约</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >指在平台交易过程中，个别平台客户需实地看货的，乙方不得违反双方约定，给平台造成损失的行为，包括但不限于以下情形：</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >通过不正当手段，获取平台客户信息（包括但不限于，客户公司名称、通讯联系方式、采购需求等一切商务信息）。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">泄露以上客户信息</span>
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.1.8诱导脱离平台交易</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >乙方用户诱导其他乙方用户脱离苗仓“地被直采中心”平台进行交易的行为。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt; color: #ff0000"
        >10.1.9其他售后或服务违规（待确定）</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体"
        >指依照国家相关法律法规以及苗仓“地被直采中心”平台等有关规则、标准，乙方未能按照售后承诺提供相应的售后服务的（包括但不限于售后服务响应超时、售后服务满意度指标不达标等）。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.2违规处理</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >10.2.1当乙方违反本规则，或者出现疑似违反本规则行为时，平台有权根据乙方违规的严重程度对该账号进行处如下处罚，由乙方自行承担。如果情节严重构成犯罪的，平台将依法转交至公安机关等相关部门处理。</span
      >
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="TableGrid"
      style="
        margin-right: 9pt;
        margin-left: 9pt;
        border: 0.75pt solid #000000;
        -aw-border: 0.5pt single;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
        float: left;
      "
    >
      <tbody>
        <tr>
          <td
            rowspan="3"
            style="
              width: 134.5pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: middle;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规内容</span>
            </p>
          </td>
          <td
            colspan="5"
            style="
              width: 336.55pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
            "
          >
            <p style="text-align: center; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >对应处罚措施</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">第一次</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">第二次</span>
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">第三次</span>
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">第四次</span>
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">第五次</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">处罚措施</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">处罚措施</span>
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">处罚措施</span>
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">处罚措施</span>
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">处罚措施</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >上架价格违规</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规警告</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规公示</span>
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >支付违约金</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">封禁帐号</span>
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >上架库存违规</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规警告</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规公示</span>
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >支付违约金</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">封禁帐号</span>
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >订单管理违规</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规警告</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规公示</span>
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >支付违约金</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">封禁帐号</span>
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >骗取他人财物</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >操纵交易或干扰交易系统</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >临时增加费用</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规警告</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规公示</span>
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >支付违约金</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">封禁帐号</span>
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >实地看货违约</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >诱导脱离平台交易</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span
                style="font-family: 宋体; font-size: 10.5pt; -aw-import: ignore"
                >&nbsp;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 134.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >售后服务违规</span
              >
            </p>
          </td>
          <td
            style="
              width: 61.2pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规警告</span>
            </p>
          </td>
          <td
            style="
              width: 51.9pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">违规公示</span>
            </p>
          </td>
          <td
            style="
              width: 63.85pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt"
                >支付违约金</span
              >
            </p>
          </td>
          <td
            style="
              width: 57.85pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">封禁帐号</span>
            </p>
          </td>
          <td
            style="
              width: 58.55pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; line-height: 20pt">
              <span style="font-family: 宋体; font-size: 10.5pt">清退</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体">说明：</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >平台在处理违规行为时，有权以普通或非专业人员的知识水平标准对用户提交的材料或证据做出作出表面审核，并依据该材料、证据作出判断，且不需要因可能存在的误判承担任何责任。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >如乙方因不可抗力因素（如地震、泥石流、台风、洪水等自然灾害，或大面积断电、政府干预、战争、恐怖袭击、黑客攻击等人为因素引发的不可抗力因素）导致无法遵守或履行本规则的，不在本规则约束范围内，由双方自行协商解决所遇到的问题，但乙方有及时通知平台的义务。</span
      >
    </p>
    <p style="margin-top: 3.25pt; margin-left: 0.85pt; line-height: 20pt">
      <span style="font-family: 黑体; letter-spacing: 0.15pt"
        >10.3违规处罚的扣罚</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >违规内容中对应处罚措施的“骗取他人钱物”、“操作交易或干扰交易系统”、“实地看货违规”、“诱导脱离平台交易”第一次违规会导致乙方服务帐号冻结关闭并清退，不退回诚信保证金（3000元）。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体"
        >除以上违规内容外的其它违规，会导致如下保证金的扣罚</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >违规内容中对应处罚措施的第一次违规警告，扣除保证金500元，待乙方整改合格后，补足诚信保证金，再恢复供应资格。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >违规内容中对应处罚措施的第二次违规公示，扣除保证金800元，待乙方整改合格后，补足诚信保证金，再恢复供应资格。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >违规内容中对应处罚措施的第三次支付违约金，扣除保证金1000元，待乙方整改合格后，补足诚信保证金，再恢复供应资格。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >违规内容中对应处罚措施的第四次封禁帐号，扣除保证金2000元，待乙方整改合格后，补足诚信保证金，再恢复供应资格。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >违规内容中对应处罚措施的第五次清退，扣除保证金3000元。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十一条 知识产权</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >11.1甲方拥用苗仓“地被直采中心”平台一切所有权（包括商业产权及知识产权等一切权利）。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >11.2未经甲方书面许可同意，乙方不得以任何理由自行或委托第三方以任何方式使用苗仓及其关联公司的企业名称、企业字号、商标、LOGO、活动品牌等内容从事任何活动，否则甲方有权立即终止本协议，并有权要求乙方赔偿全部损失（包括但不限于调查取证费用、公证费用、律师费等）。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十二条 违约责任</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >12.1乙方违反本协议各项约定或其它有关法律法规规定的，甲方有权要求乙方承担相应的违约责任，乙方应当全额补偿因此导致平台的任何经济损失或任何间接损失的费用，包括但不限于赔偿、律师费用、诉讼仲裁费用、财务费用、差旅费用、行政处罚或其他任何追索，违约责任的赔偿则根据造成的直接及间接经济损失计算赔偿的金额。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >12.2本协议履行期间，若甲方平台违反本协议约定导致乙方遭受损失的，乙方有权要求甲方承担违约责任，违约责任的赔偿则根据违约造成的直接及间接经济损失计算赔偿的金额。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十三条 免责</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >13.1因不可抗力致使协议不能正常履行的，无需承担违约责任。不可抗力是指不能预见、不能避免并不能克服的客观情况，包括但不限于：战争、骚乱、洪水、地震、台风、国家公布的疫情等事件。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >13.2甲方平台因下列状况无法正常运作，使您无法使用各项服务，甲方不承担损害赔偿责任，该状况包括但不限于：</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">平台提前公告系统停机维护期间；</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >因停电、系统故障、传输线路、通信故障及通信线路等不可预测因素造成不能提供服务的；</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >由于黑客攻击、电信部门调整或故障、网站升级、第三方方面的问题等原因而造成的服务中断、终止或者延迟。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体"
        >对于上述情形，平台应尽可能减少您损失，并于发生该些情况后尽快通知您。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十四条 反商业贿赂条款</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >14.1商家应当严格遵守法律法规有关禁止商业贿赂行为规定，坚决拒绝商业贿赂、行贿及其他不正当商业行为的馈赠，不得通过平台及/或其关联公司的员工谋取不正当利益。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >14.2若甲方平台及/或其关联公司员工要求乙方给予其任何形式的不正当利益，乙方应及时投诉，并提供相关证据给甲方。甲方相关人员查实后作出处理，并为乙方保密。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >14.3乙方向甲方承诺，就甲方进行的任何合规审计和调查合作提供甲方要求的全部合理信息和协助。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >14.4若乙方违反本反商业贿赂条款的任何约定，甲方有权立即解除本协议，停止与乙方在本协议或任何其他合同项下的部分或全部合作，并赔偿甲方因此遭受的全部损失。违约条款与本条约定不一致的，以本条约定为准。情节严重、造成重大经济损失的，甲方将依法移交有权机关处理。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十五条 法律适用和争议解决</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >15.1本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并提交甲方（中山苗仓供应链管理有限公司）所在地有管辖权的人民法院诉讼。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十六条 其他</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >16.1乙方同意本协议的，即视为确认自己具有使用平台相应的权利能力和行为能力，已知悉并完全理解本协议的全部内容，能够独立承担法律责任。</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 20pt">
      <span style="font-family: 宋体"
        >16.2
        本协议所指的通知，包括但不限于短信、电话、苗仓“地被直采中心”小程序端信息公告等。</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 黑体; font-size: 13pt; font-weight: bold"
        >第十七条：合同附件</span
      >
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">相关标准：《供应商服务标准》</span>
    </p>
    <p style="line-height: 20pt">
      <span style="font-family: 宋体; font-size: 6.5pt">●</span
      ><span style="font-family: 宋体">《供应产品明细表》见附页</span>
    </p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p>
      <span style="font-family: 华文细黑">甲方（公章）：</span
      ><span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="font-family: 华文细黑">&nbsp;</span
      ><span style="font-family: 华文细黑">&nbsp;</span
      ><span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="font-family: 华文细黑">乙方（公章）：</span
      ><span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p>
      <span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="font-family: 华文细黑">&nbsp;</span>
    </p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p>
      <span style="font-family: 华文细黑">授权代表（签字）：</span
      ><span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="font-family: 华文细黑">授权代表（签字）：</span
      ><span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;
      </span>
    </p>
    <p>
      <span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="font-family: 华文细黑">&nbsp;</span>
    </p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p>
      <span style="font-family: 华文细黑">____年____月____日</span
      ><span style="font-family: 华文细黑; -aw-import: spaces"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span style="font-family: 华文细黑">____年____月____日</span>
    </p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p><span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span></p>
    <p style="text-align: center; font-size: 15pt">
      <span style="font-family: 黑体">供应产品明细表</span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="TableGrid"
      style="
        border: 0.75pt solid #000000;
        -aw-border: 0.5pt single;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">序号</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">苗木名称</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">高度</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">冠幅</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">上架库存数量</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">产品图片</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">1</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">2</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">3</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">4</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">5</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">6</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">7</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">8</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">9</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">10</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">11</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">12</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">13</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">14</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">15</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style="height: 34pt; -aw-height-rule: exactly">
          <td
            style="
              width: 27.6pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体">16</span>
            </p>
          </td>
          <td
            style="
              width: 115.4pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 61pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 64.2pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 89.3pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
          <td
            style="
              width: 71.5pt;
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p style="text-align: justify; font-size: 12pt">
              <span style="font-family: 黑体; -aw-import: ignore">&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="font-size: 14pt">
      <span style="font-family: 华文细黑; -aw-import: ignore">&nbsp;</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "SupplierCooperation",

  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>

<style>
body {
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
h1,
h2,
h3,
p {
  margin: 0pt;
}
table {
  margin-top: 0pt;
  margin-bottom: 0pt;
}
h1 {
  margin-top: 17pt;
  margin-bottom: 16.5pt;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 240%;
  font-family: 等线;
  font-size: 22pt;
  font-weight: bold;
  color: #000000;
}
h2 {
  margin-top: 13pt;
  margin-bottom: 13pt;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 172%;
  font-family: Arial;
  font-size: 16pt;
  font-weight: bold;
  color: #000000;
}
h3 {
  margin-top: 13pt;
  margin-bottom: 13pt;
  text-align: justify;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 172%;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 16pt;
  font-weight: bold;
  color: #000000;
}
.CommentText {
  text-align: left;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.Footer {
  text-align: left;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 9pt;
}
.Header {
  text-align: justify;
  line-height: normal;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 9pt;
}
.NormalWeb {
  margin: 5pt 0pt;
  text-align: left;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 12pt;
}
.TOC1 {
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.TOC2 {
  margin-left: 21pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.TOC3 {
  margin-left: 42pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-family: Calibri;
  font-size: 10.5pt;
}
.WPSOffice1 {
  font-size: 10pt;
}
.WPSOffice2 {
  font-size: 10pt;
}
.WPSOffice3 {
  font-size: 10pt;
}
span.\31 Char {
  font-size: 22pt;
  font-weight: bold;
}
span.\32 Char {
  font-family: Arial;
  font-size: 16pt;
  font-weight: bold;
}
.page {
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  word-break: break-all;
}
</style>
